import { render, staticRenderFns } from "./ClassifierByType.vue?vue&type=template&id=74caab04&scoped=true&"
import script from "./ClassifierByType.vue?vue&type=script&lang=js&"
export * from "./ClassifierByType.vue?vue&type=script&lang=js&"
import style0 from "./ClassifierByType.vue?vue&type=style&index=0&id=74caab04&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74caab04",
  null
  
)

export default component.exports