<template>
  <vs-popup
    class="user-edit-popup"
    id="popup"
    title="ADD CLASSIFIER TRANSLATION"
    @close="open = false"
    :active.sync="open"
    :buttons-hidden="true"
  >
    <div class="vx-row mb-3">
      <div class="vx-col md:w-full w-full md:mb-0">
        <small class="label">Language</small>
        <v-select
          v-model="form.Language"
          :options="current_user_language_array"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        />
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col md:w-full w-full md:mb-0">
        <small class="label">Value</small>
        <vs-input
          required
          v-model="form.Value"
          class="w-full"
          name="name"
          type="text"
          label=""
        />
      </div>
    </div>
    <div class="flex flex-wrap items-center justify-end mt-8">
      <vs-button
        id="save_change"
        @click="onCLickSave"
        button="submit"
        class="ml-auto mt-2"
        >Save Changes</vs-button
      >
      <!--      <vs-button @click="name = ''" class="ml-4 mt-2" type="border" color="warning">Clear</vs-button>-->
    </div>
  </vs-popup>
</template>

<script>
import { RepositoryFactory } from '@/repository/RepositoryFactory';
import ErrorMessages from '@/mixins/ErrorMessages';
import SuccessMessage from '@/mixins/SuccessMessage';
const ClassifierRepository = RepositoryFactory.get('classifier');
import vSelect from 'vue-select';
import store from '../../../store/store';

function initialState() {
  return {
    open: false,
    id: null,
    form: {},
    current_user_language_array: store.getters.current_user_language_array,
  };
}
export default {
  name: 'ChildAddNodePopUp',
  data() {
    return initialState();
  },
  components: {
    'v-select': vSelect,
  },
  mixins: [ErrorMessages, SuccessMessage],
  methods: {
    async openPopup(id) {
      await Object.assign(this.$data, initialState());
      this.open = true;
      this.id = id;
    },
    async onCLickSave() {
      const vue = this;
      this.$vs.loading({
        container: '#save_change',
        scale: 0.45,
      });
      try {
        await ClassifierRepository.addTranslation(this.id, this.form);
        this.$vs.loading.close();
        this.showSuccessMessage('Translation add successfully!');
        this.open = false;
      } catch (e) {
        this.$vs.loading.close('#save_change > .con-vs-loading');
        this.open = false;
        this.showErrorMessage('Something went wrong!');
      }
      this.$vs.loading.close('#save_change > .con-vs-loading');
    },
  },
};
</script>

<style scoped>
</style>
