<template>
  <vs-popup
    class="user-edit-popup"
    id="popup"
    title="Classifiers Update"
    @close="open = false"
    :active.sync="open"
    :buttons-hidden="true"
  >
    <div class="vx-row mb-3">
      <div class="vx-col md:w-1/2 w-full md:mb-0">
        <small class="label">Code</small>
        <vs-input
          v-model="form.Code"
          class="w-full"
          name="name"
          type="text"
          label=""
        />
      </div>
      <div class="vx-col md:w-1/2 w-full md:mb-0">
        <small class="label">Name</small>
        <vs-input
          v-model="form.Name"
          class="w-full"
          name="name"
          type="text"
          label=""
        />
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col md:w-full w-full md:mb-0">
        <small class="label">Remark</small>
        <vs-input
          v-model="form.Remark"
          class="w-full"
          name="name"
          type="text"
          label=""
        />
      </div>
    </div>
    <div class="flex flex-wrap items-center justify-end mt-8">
      <vs-button
        id="save_change"
        @click="onClickSave"
        button="submit"
        class="ml-auto mt-2"
        >Save Changes</vs-button
      >
      <!--      <vs-button @click="name = ''" class="ml-4 mt-2" type="border" color="warning">Clear</vs-button>-->
    </div>
  </vs-popup>
</template>

<script>
import { RepositoryFactory } from '@/repository/RepositoryFactory';
const ClassifierRepository = RepositoryFactory.get('classifier');
import ErrorMessages from '@/mixins/ErrorMessages';
import SuccessMessage from '@/mixins/SuccessMessage';

function initialState() {
  return {
    open: false,
    name: '',
    id: null,
    form: {},
  };
}

export default {
  name: 'UpdateNodePopUp',
  data() {
    return initialState();
  },
  mixins: [ErrorMessages, SuccessMessage],
  methods: {
    async openPopup(data) {
      await Object.assign(this.$data, initialState());
      this.id = data.Id;
      await this.loadClassifierData();
      this.open = true;
      // this.form = data
    },
    async loadClassifierData() {
      const data = (await ClassifierRepository.getOne(this.id)).data;
      const temp_data = data[0];
      this.form = {
        Code: temp_data.Code,
        Name: temp_data.Name.find((n) => n.Language === 'EST').Value,
        Remark: temp_data.Remark,
      };
    },
    async onClickSave() {
      const vue = this;
      this.$vs.loading();
      try {
        await ClassifierRepository.update(this.id, this.form);
        this.$vs.loading.close();
        this.showSuccessMessage('The selected Classifier update successfully!');
        this.open = false;
      } catch (e) {
        this.$vs.loading.close();
        this.showErrorMessage('Something went wrong!');
      }
      await vue.$parent.dataRefresh();
      this.$vs.loading.close();
    },
  },
};
</script>

<style scoped>
</style>
