<template>
  <vs-popup
    class="user-edit-popup"
    id="popup"
    title="Classifier Translations"
    @close="open = false"
    :active.sync="open"
    :buttons-hidden="true"
  >
    <div class="vx-col w-full">
      <vx-card title="">
        <template slot="actions">
          <div class="flex flex-centered">
            <vs-button
              v-if="availableLanArray.length > 0"
              @click="popupActivo3 = true"
              class
              color="primary"
              type="filled"
              position="left"
              >Add Translations
            </vs-button>
            <!--Popup For Add Translation First Inner popup-->
            <vs-popup title="Add Translation" :active.sync="popupActivo3">
              <div class="vx-row mb-3">
                <div class="vx-col md:w-full w-full md:mb-0">
                  <small class="label">Language</small>
                  <v-select
                    v-model="form.Language"
                    :options="availableLanArray"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  />
                </div>
              </div>
              <div class="vx-row mb-3">
                <div class="vx-col md:w-full w-full md:mb-0">
                  <small class="label">Value</small>
                  <vs-input
                    required
                    v-model="form.Value"
                    class="w-full"
                    name="name"
                    type="text"
                    label=""
                  />
                </div>
              </div>
              <div class="flex flex-wrap items-center justify-end mt-8">
                <vs-button
                  id="save_change"
                  @click="onCLickSave"
                  button="submit"
                  class="ml-auto mt-2"
                  >Save Changes
                </vs-button>
              </div>
            </vs-popup>
          </div>
        </template>
        <template slot="actions">
          <div class="flex flex-centered">
            <!--Popup For Update Second Innner Popup-->
            <vs-popup title="Update Translation" :active.sync="popupActivo2">
              <div class="vx-row mb-3">
                <div class="vx-col md:w-full w-full md:mb-0">
                  <small class="label">Language</small>
                  <vs-input
                    readonly
                    v-model="form.Language"
                    class="w-full"
                    name="name"
                    type="text"
                    label=""
                  />
                </div>
              </div>
              <div class="vx-row mb-3">
                <div class="vx-col md:w-full w-full md:mb-0">
                  <small class="label">Value</small>
                  <vs-input
                    required
                    v-model="form.Value"
                    class="w-full"
                    name="name"
                    type="text"
                    label=""
                  />
                </div>
              </div>
              <div class="flex flex-wrap items-center justify-end mt-8">
                <vs-button
                  id=""
                  @click="onCLickUpdate"
                  button="submit"
                  class="ml-auto mt-2"
                  >Update Translation
                </vs-button>
              </div>
            </vs-popup>
          </div>
        </template>

        <div>
          <vs-table
            :data="lan_array"
            max-items="5"
            pagination
            stripe
            class="table-dark-inverted table-auto vs-con-loading__container"
          >
            <template slot="thead">
              <vs-th>Language</vs-th>
              <vs-th>Value</vs-th>
              <vs-th class="content-center">Action</vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].Language">{{
                  data[indextr].Language
                }}</vs-td>
                <vs-td :data="data[indextr].Value">
                  {{ data[indextr].Value }}
                </vs-td>
                <vs-td>
                  <div class="vx-row">
                    <vx-tooltip
                      v-if="data[indextr].TranslationId !== null"
                      class="mr-2"
                      text="Edit"
                      position="right"
                    >
                      <vs-button
                        radius
                        color="primary"
                        type="border"
                        icon-pack="feather"
                        icon="icon-edit"
                        @click="openUpdatePopup(data[indextr])"
                      >
                      </vs-button>
                    </vx-tooltip>
                    <vx-tooltip
                      v-if="data[indextr].TranslationId !== null"
                      class="mr-2"
                      text="Delete"
                      position="right"
                    >
                      <vs-button
                        radius
                        color="danger"
                        type="border"
                        icon-pack="feather"
                        icon="icon-trash-2"
                        @click="openConfirmDelete(data[indextr].TranslationId)"
                      >
                      </vs-button>
                    </vx-tooltip>
                  </div>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </vx-card>
    </div>
  </vs-popup>
</template>

<script>
import { RepositoryFactory } from '@/repository/RepositoryFactory';

const ClassifierRepository = RepositoryFactory.get('classifier');
const TranslationRepository = RepositoryFactory.get('translation');
import ErrorMessages from '@/mixins/ErrorMessages';
import SuccessMessage from '@/mixins/SuccessMessage';
import vSelect from 'vue-select';
import store from '../../../store/store';

function initialState() {
  return {
    open: false,
    popupActivo3: false,
    popupActivo2: false,
    form: {},
    id: null,
    current_user_language_array: store.getters.current_user_language_array,
    lan_array: [],
    availableLanArray: [],
  };
}

export default {
  name: 'LanguageAddNodePopUp',
  data() {
    return initialState();
  },
  components: {
    'v-select': vSelect,
  },
  mixins: [ErrorMessages, SuccessMessage],
  methods: {
    //Open The Parent Popup
    async openPopup(id) {
      await Object.assign(this.$data, initialState());
      this.id = id;
      await this.loadClassifierData();
      await this.initialLanguageMap();
      this.open = true;
    },
    async initialLanguageMap() {
      const exists_languages = this.lan_array.map((m) => m.Language);
      this.availableLanArray = this.current_user_language_array.filter(
        function (obj) {
          return exists_languages.indexOf(obj) === -1;
        },
      );
    },

    //Load Data For Table in Popud
    async loadClassifierData() {
      this.$vs.loading();
      const data = (await ClassifierRepository.getOne(this.id)).data;
      const temp_data = data[0];
      this.lan_array = temp_data.Name;
      this.$vs.loading.close();
    },

    //Save New Translation
    async onCLickSave() {
      this.$vs.loading();
      try {
        await ClassifierRepository.addTranslation(this.id, this.form);
        this.$vs.loading.close();
        this.showSuccessMessage('Translation add successfully!');
        this.popupActivo3 = false;
      } catch (e) {
        this.$vs.loading.close();
        this.showErrorMessage('Something went wrong!');
      }
      this.$vs.loading.close();
      await this.dataRefresh();
    },

    //Save Updated Data in Second Inner Popup
    async onCLickUpdate() {
      this.$vs.loading();
      try {
        await TranslationRepository.update(this.form.TranslationId, this.form);
        this.$vs.loading.close();
        this.showSuccessMessage(
          'The selected Classifier translation update successfully!',
        );
        this.popupActivo2 = false;
        await this.dataRefresh();
      } catch (e) {
        this.showErrorMessage('Something went wrong!');
      }
      this.$vs.loading.close();
    },

    //Open Second Inner Popup
    async openUpdatePopup(data) {
      this.$vs.loading();
      await this.loadTranslationData(data.TranslationId);
      this.popupActivo2 = true;
      this.$vs.loading.close();
    },

    //Load Existing data into Upate Popup
    async loadTranslationData(translationId) {
      const data = (await TranslationRepository.get(translationId)).data;
      const temp_data = data[0];
      this.form = {
        Language: temp_data.Language,
        Value: temp_data.Value,
        TranslationId: translationId,
      };
    },

    //Delete Action
    openConfirmDelete(Id) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm',
        text: 'Are you sure want to delete selected classifier?',
        accept: this.onConfirmDeleteTranslation,
        parameters: [Id],
      });
      this.open = false;
    },

    //Delete Confirmation
    async onConfirmDeleteTranslation(params) {
      this.$vs.loading();
      await TranslationRepository.delete(params[0]);
      this.$vs.loading.close();
      this.showSuccessMessage(
        'The selected classifier was successfully deleted',
      );
      this.open = true;
      await this.dataRefresh();
    },
    //Delete Cancel
    async onDeleteCancel() {
      this.open = true;
    },

    //Updating Parent Popup
    async dataRefresh() {
      this.form = {};
      await this.loadClassifierData();
      await this.initialLanguageMap();
    },
  },
};
</script>

<style scoped>
</style>
