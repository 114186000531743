<template>
  <div>
    <!--   <vx-card ref="filterCard" title="Filters" class="user-list-filters mb-8" :collapse-action="true">-->
    <!--     <div class="vx-row">-->
    <!--       <div class="vx-col md:w-1/4 sm:w-1/2 w-full">-->
    <!--         <label class="text-sm opacity-100 text-bold">Name</label>-->

    <!--         <vs-input type="search" v-model="searchTerm"  placeholder="Search.."  class="mb-4 md:mb-0 w-full"/>-->
    <!--       </div>-->
    <!--     </div>-->

    <!--     <div class="vx-row mt-5">-->
    <!--       <div class="vx-col w-full ml-auto">-->
    <!--         <vs-button class="float-right mr-4" size="small"-->
    <!--                    color="danger" type="border" icon-pack="feather" >CLEAR</vs-button>-->
    <!--         <vs-button class="float-right mr-4" size="small" @click="onClickSearch"-->
    <!--                    color="success" type="border" icon-pack="feather" >SEARCH</vs-button>-->
    <!--       </div>-->
    <!--     </div>-->
    <!--   </vx-card>-->
    <div class="vx-row">
      <!-- CARD 9: DISPATCHED ORDERS -->
      <div class="vx-col w-full">
        <vx-card title="Classifiers">
          <div slot="actions">
            <div class="flex flex-centered" v-if="hasTranslatableActions">
              <!--             <v-select class="mr-4" v-model="lan" :reduce="option => option" :options="current_user_language_array" :dir="$vs.rtl ? 'rtl' : 'ltr'" />-->
              <div class="d-flex mt-2">
                <vs-button
                  :key="lan_value"
                  v-for="lan_value in current_user_language_array"
                  @click="changeLan(lan_value)"
                  v-bind:class="{ 'button-highlighted': lan === lan_value }"
                  class="mr-4"
                  size="small"
                  color="success"
                  type="border"
                  icon-pack="feather"
                  >{{ lan_value }}</vs-button
                >
              </div>
              <vs-button
                v-if="classifiers && classifiers[0].Editable"
                @click="onClickAddClassifier"
                class
                color="primary"
                type="filled"
                >Create Classifier</vs-button
              >
            </div>
          </div>
          <template lang="html">
            <div>
              <vs-table
                search
                max-items="10"
                pagination
                :data="classifiers"
                stripe
                class="table-dark-inverted table-auto vs-con-loading__container"
              >
                <template slot="thead">
                  <vs-th>Code</vs-th>
                  <vs-th>Name (EST)</vs-th>
                  <vs-th v-if="lan !== 'EST'">Name ({{ lan }})</vs-th>
                  <vs-th class="content-center"></vs-th>
                </template>

                <template slot-scope="{ data }">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr].Code">{{
                      data[indextr].Code
                    }}</vs-td>
                    <vs-td :data="data[indextr].Code">
                      {{
                        getTranslation({
                          lang: 'EST',
                          value: data[indextr].Name,
                        })
                      }}
                    </vs-td>
                    <vs-td v-if="lan !== 'EST'" :data="data[indextr].Code">
                      {{ tableTranslations(data[indextr].Name) }}
                    </vs-td>
                    <vs-td>
                      <div class="vx-row">
                        <vx-tooltip
                          v-if="data[indextr].Editable"
                          class="mr-2"
                          text="Edit"
                          position="right"
                        >
                          <vs-button
                            @click="onCLickUpdate(data[indextr])"
                            radius
                            color="primary"
                            type="border"
                            icon-pack="feather"
                            icon="icon-edit"
                          ></vs-button>
                        </vx-tooltip>
                        <vx-tooltip
                          v-if="data[indextr].Editable"
                          class="mr-2"
                          text="Delete"
                          position="right"
                        >
                          <vs-button
                            @click="openConfirmDelete(data[indextr].Id)"
                            radius
                            color="danger"
                            type="border"
                            icon-pack="feather"
                            icon="icon-trash-2"
                          ></vs-button>
                        </vx-tooltip>
                        <vx-tooltip
                          v-if="data[indextr].Translatable"
                          text="Classifier Translation"
                          position="right"
                        >
                          <vs-button
                            @click="openLanguagePopUp(data[indextr].Id)"
                            radius
                            color="primary"
                            type="border"
                            icon-pack="feather"
                            icon="icon-globe"
                          ></vs-button>
                        </vx-tooltip>
                      </div>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </template>
        </vx-card>
      </div>
    </div>
    <add-classifier-pop-up ref="addClassifierPopUp" />
    <update-classifier-pop-up ref="updateClassifierPopUp" />
    <add-classifier-translation-pop-up ref="addClassifierTranslationPopUp" />
    <add-classifier-language-pop-up ref="addClassifierLanguagePopUp" />
  </div>
</template>

<script>
import { RepositoryFactory } from '@/repository/RepositoryFactory';
const ClassifierRepository = RepositoryFactory.get('classifier');
import AddClassifierPopUp from '@/components/popups/classifiers/AddClassifierPopUp';
import AddClassifierLanguagePopUp from '@/components/popups/classifiers/AddClassifierLanguagePopUp';
import UpdateClassifierPopUp from '@/components/popups/classifiers/UpdateClassifierPopUp';
import AddClassifierTranslationPopUp from '@/components/popups/classifiers/AddClassifierTranslationPopUp';
import SuccessMessage from '@/mixins/SuccessMessage';
import vSelect from 'vue-select';
import store from '@/store/store';

export default {
  name: 'ClassifierList',
  mixins: [SuccessMessage],
  components: {
    AddClassifierPopUp,
    UpdateClassifierPopUp,
    AddClassifierTranslationPopUp,
    AddClassifierLanguagePopUp,
    'v-select': vSelect,
  },
  data() {
    return {
      classifiers: [],
      type: this.$route.params.type,
      searchTerm: 'storagetemperature',
      lan: store.getters.currentLanguage || 'EST',
      current_user_language_array: store.getters.current_user_language_array,
    };
  },
  async mounted() {
    await this.searchProducts();
  },
  computed: {
    hasTranslatableActions() {
      return (
        this.classifiers &&
        this.classifiers[0] &&
        this.classifiers[0].Translatable
      );
    },
  },
  methods: {
    async dataRefresh() {
      await this.searchProducts();
    },
    async searchProducts() {
      this.$vs.loading();
      this.classifiers = (await ClassifierRepository.search(this.type)).data;
      console.log(this.classifiers);
      this.$vs.loading.close();
    },
    async onClickSearch() {
      await this.searchProducts();
    },
    async onClickAddClassifier() {
      await this.$refs.addClassifierPopUp.openPopup(this.type);
    },
    async onCLickUpdate(data) {
      // todo : need API support GET {classifierId}
      await this.$refs.updateClassifierPopUp.openPopup(data);
    },
    async openAddTranslationPopUp(Id) {
      await this.$refs.addClassifierTranslationPopUp.openPopup(Id);
    },
    async openLanguagePopUp(Id) {
      await this.$refs.addClassifierLanguagePopUp.openPopup(Id);
    },
    openConfirmDelete(Id) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm',
        text: 'Are you sure want to delete selected classifier?',
        accept: this.onConfirmDeleteClassifiers,
        parameters: [Id],
      });
    },
    async onConfirmDeleteClassifiers(params) {
      this.$vs.loading();
      await ClassifierRepository.delete(params[0]);
      this.$vs.loading.close();
      await this.searchProducts();
      this.showSuccessMessage(
        'The selected classifier was successfully deleted',
      );
    },
    async changeLan(lan) {
      this.lan = lan;
    },
    getTranslation({ lang, value, fallbackLang = 'EST' }) {
      if (!value) {
        return '';
      }
      const resultWithFallback = value.filter((n) => {
        return n.Language === lang || n.Language === fallbackLang;
      });

      return resultWithFallback.length === 2
        ? resultWithFallback.find((n) => n.Language === lang).Value
        : resultWithFallback[0].Value;
    },
    tableTranslations(Name) {
      return this.getTranslation({
        lang: this.lan,
        value: Name,
      });
    },
  },
};
</script>

<style scoped>
.button-highlighted {
  background: #64c272 !important;
  color: white !important;
}
</style>
