<template>
  <vs-popup
    class="user-edit-popup"
    id="popup"
    title="Classifier Create"
    @close="open = false"
    :active.sync="open"
    :buttons-hidden="true"
  >
    <div class="vx-row mb-3">
      <div class="vx-col md:w-1/2 w-full md:mb-0">
        <small class="label">Code</small>
        <vs-input
          v-model="form.Code"
          class="w-full"
          name="name"
          type="text"
          label=""
        />
      </div>
      <div class="vx-col md:w-1/2 w-full md:mb-0">
        <small class="label">Name</small>
        <vs-input
          v-model="form.Name"
          class="w-full"
          name="name"
          type="text"
          label=""
        />
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col md:w-full w-full md:mb-0">
        <small class="label">Remark</small>
        <vs-input
          v-model="form.Remark"
          class="w-full"
          name="name"
          type="text"
          label=""
        />
      </div>
    </div>
    <div class="flex flex-wrap items-center justify-end mt-8">
      <vs-button @click="onCLickSave" button="submit" class="ml-auto mt-2"
        >Save Changes</vs-button
      >
      <!--      <vs-button @click="form = {}" class="ml-4 mt-2" type="border" color="warning">Clear</vs-button>-->
    </div>
  </vs-popup>
</template>

<script>
import { RepositoryFactory } from '@/repository/RepositoryFactory';
const ClassifierRepository = RepositoryFactory.get('classifier');
import ErrorMessages from '@/mixins/ErrorMessages';
import SuccessMessage from '@/mixins/SuccessMessage';

function initialState() {
  return {
    open: false,
    name: '',
    type: null,
    form: {},
  };
}

export default {
  name: 'RootAddNodePopUp',
  data() {
    return initialState();
  },
  mixins: [ErrorMessages, SuccessMessage],
  methods: {
    async openPopup(type) {
      await Object.assign(this.$data, initialState());
      this.open = true;
      this.type = type;
    },
    async onCLickSave() {
      const vue = this;
      this.$vs.loading();
      try {
        await ClassifierRepository.create(this.type, this.form);
        this.$vs.loading.close();
        this.showSuccessMessage('Classifier create successfully!');
        await vue.$parent.dataRefresh();
        this.open = false;
      } catch (e) {
        this.showErrorMessage('Something went wrong');
        this.$vs.loading.close();
      }
      this.$vs.loading.close();
    },
  },
};
</script>

<style scoped>
</style>
